<template>
  <a-layout-header :class="[headerTheme, 'admin-header']">
    <div :class="['admin-header-wide', layout, pageWidth]">
      <!-- <router-link v-if="isMobile || layout === 'head'" to="/" :class="['logo', isMobile ? null : 'pc', headerTheme]">
        <img width="32" src="@/assets/img/logo.png" />
        <h1 v-if="!isMobile">{{systemName}}</h1>
      </router-link> -->
      <a-divider v-if="isMobile" type="vertical" />
      <!-- <a-icon v-if="layout !== 'head'" class="trigger trigger-icon" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggleCollapse"/> -->
      <div v-if="layout !== 'side' && !isMobile" class="admin-header-menu" :style="`width: ${menuWidth};`">
        <i-menu class="head-menu" :theme="headerTheme" mode="horizontal" :options="menuData" @select="onSelect"/>
      </div>
      <img src="@/assets/img/logo6.png" alt="" style="height: 54px;">
      <div :class="['admin-header-right', headerTheme]">
          <!-- <header-search class="header-item" @active="val => searchActive = val" />
          <a-tooltip class="header-item" title="帮助文档" placement="bottom" >
            <a href="https://iczer.gitee.io/vue-antd-admin-docs/" target="_blank">
              <a-icon type="question-circle-o" />
            </a>
          </a-tooltip>
          <header-notice class="header-item"/> -->
          <header-theme v-show="false"></header-theme>
          <a-button class="area-button" @click="areaRouteShow = true"> 
            <svg-icon icon-class="area-icon" class="icon"/>全站地图
          </a-button>
          <header-avatar class="header-item"/>
          <!-- <a-dropdown class="lang header-item">
            <div>
              <a-icon type="global"/> {{langAlias}}
            </div>
            <a-menu @click="val => setLang(val.key)" :selected-keys="[lang]" slot="overlay">
              <a-menu-item v-for=" lang in langList" :key="lang.key">{{lang.key.toLowerCase() + ' ' + lang.name}}</a-menu-item>
            </a-menu>
          </a-dropdown> -->
      </div>

    </div>
    <header-route :areaRouteShow="areaRouteShow" @close="areaRouteShow = false"></header-route>
  </a-layout-header>
</template>

<script>
import HeaderSearch from './HeaderSearch'
import HeaderNotice from './HeaderNotice'
import HeaderAvatar from './HeaderAvatar'
import HeaderTheme from './HeaderTheme'
import HeaderRoute from './HeaderRoute'
import IMenu from '@/components/menu/menu'
import {mapState, mapMutations} from 'vuex'

export default {
  name: 'AdminHeader',
  components: {IMenu, HeaderAvatar, HeaderTheme, HeaderRoute, HeaderNotice, HeaderSearch},
  props: ['collapsed', 'menuData'],
  data() {
    return {
      langList: [
        {key: 'CN', name: '简体中文', alias: '简体'},
        {key: 'HK', name: '繁體中文', alias: '繁體'},
        {key: 'US', name: 'English', alias: 'English'}
      ],
      searchActive: false,
      areaRouteShow: false
    }
  },
  computed: {
    ...mapState('setting', ['theme', 'isMobile', 'layout', 'systemName', 'lang', 'pageWidth']),
    headerTheme () {
      if (this.layout == 'side' && this.theme.mode == 'dark' && !this.isMobile) {
        return 'light'
      }
      return this.theme.mode
    },
    langAlias() {
      let lang = this.langList.find(item => item.key == this.lang)
      return lang.alias
    },
    menuWidth() {
      const {layout, searchActive} = this
      const headWidth = layout === 'head' ? '100% - 188px' : '100%'
      const extraWidth = searchActive ? '600px' : '400px'
      return `calc(${headWidth} - ${extraWidth})`
    }
  },
  methods: {
    toggleCollapse () {
      this.$emit('toggleCollapse')
    },
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    },
    ...mapMutations('setting', ['setLang'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_handle.scss';
.trigger-icon {
  @include font_color("font_color1");
}
.area-button {
  @include background_color("background_color1");
  @include font_color("font_color1");
  border: none;
  width: 124px;
  height: 36px;
  margin: 14px 34px 0 0;
  .icon {
    @include font_color("font_color3");
    margin-right: 8px;
    font-size: 20px;
  }
}
</style>

<style lang="less" scoped>
@import "index";
</style>
