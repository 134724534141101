import Vue from 'vue'
import App from './App.vue'
import {initRouter} from './router'
import './theme/index.less'
import Antd from 'ant-design-vue'
import Viser from 'viser-vue'
// import '@/mock'
import store from './store'
import 'animate.css/source/animate.css'
import Plugins from '@/plugins'
import {initI18n} from '@/utils/i18n'
import bootstrap from '@/bootstrap'
import 'moment/locale/zh-cn'
import "@/assets/styles/common.scss";
import "@/assets/styles/main.scss"
import 'video.js/dist/video-js.css'
// 全局注册Base组件
import BasePagination from "@/components/BasePagination";
// 过滤器
import filters from './filters'
// ECharts
import './components/ECharts/EChartsConfig'
// 无限滚动
import vueiInfinite from 'vue-infinite-scroll'
// $http
import VueResource from 'vue-resource'
Vue.use(VueResource)
// moment.js
import moment from "moment";
window.moment = moment
Vue.use(vueiInfinite)

import SvgIcon from '@/components/SvgIcon/SvgIcon'// svg组件
Vue.component('svg-icon', SvgIcon)
import '@/components/SvgIcon/index.js'

const router = initRouter(store.state.setting.asyncRoutes)
const i18n = initI18n('CN', 'US')

Vue.use(Antd)
Vue.config.productionTip = false
Vue.use(Viser)
Vue.use(Plugins)

// sentry
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// process.env.NODE_ENV === 'production' && Sentry.init({
//   Vue: Vue,
//   dsn: "https://95e212db60624dcbb12be894655ffda7@sentry.afanticar.cn/2",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//   tracingOptions: {
//     trackComponents: true,
//   },
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   release: "jjt@1.0",
// });


// 注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.filtrate = filters
Vue.prototype.$moment = moment;

bootstrap({router, store, i18n, message: Vue.prototype.$message})
Vue.mixin({
  components: {
    BasePagination,
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
