<template>
  <a-drawer
    title="Basic Drawer"
    placement="right"
    :closable="false"
    :visible="areaRouteShow"
    :maskStyle="maskStyle"
    width="734px"
    :headerStyle="headerStyle"
    :drawerStyle="drawerStyle"
    :bodyStyle="bodyStyle"
    @close="close"
  >
    <div class="header">
      <a-icon type="right" class="font-color2 icon1" @click="close" />
      <svg-icon icon-class="area-icon" class="icon2" />
      <span>全站地图</span>
    </div>
    <div class="container">
      <div class="tools">
        <a-input placeholder="搜索您想要的功能，如经销商管理" v-model="searchIpt" class="area-input">
          <a-icon slot="prefix" type="search" class="font-color1" style="opacity: 0.2;"/>
        </a-input>
        <a-button class="button" @click="currentIpt = searchIpt">确定</a-button>
      </div>
      <div class="button-group">
        <span v-for="(item, index) in buttonList" :key="index" @click="routerTo(item.path)">{{item.name}}</span>
      </div>
      <div class="container-main">
        <div class="container-main-col">
          <div class="item" v-for="(item,index) in mapList1" :key="index">
            <div class="item-title">
               <svg-icon :icon-class="item.icon" class="icon"/>{{item.name}}
            </div>
            <div :class="currentIpt == ic.name ? 'item-children active' : 'item-children'" v-for="(ic,i) in item.children" :key="i" @click="routerTo(ic.path)">{{ic.name}}<span class="tag" v-if="stringToObject(ic.extra).menu_status == 1">NEW</span></div>
          </div>
        </div>
        <div class="container-main-col">
          <div class="item" v-for="(item,index) in mapList2" :key="index">
            <div class="item-title">
               <svg-icon :icon-class="item.icon" class="icon"/>{{item.name}}
            </div>
            <div :class="currentIpt == ic.name ? 'item-children active' : 'item-children'" v-for="(ic,i) in item.children" :key="i" @click="routerTo(ic.path)">{{ic.name}}<span class="tag" v-if="stringToObject(ic.extra).menu_status == 1">NEW</span></div>
          </div>
        </div>
        <div class="container-main-col">
          <div class="item" v-for="(item,index) in mapList3" :key="index">
            <div class="item-title">
               <svg-icon :icon-class="item.icon" class="icon"/>{{item.name}}
            </div>
            <div :class="currentIpt == ic.name ? 'item-children active' : 'item-children'" v-for="(ic,i) in item.children" :key="i" @click="routerTo(ic.path)">{{ic.name}}<span class="tag" v-if="stringToObject(ic.extra).menu_status == 1">NEW</span></div>
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "auth"
);
export default {
  name: "HeaderRoute",
  props: ["areaRouteShow"],
  data() {
    return {
      drawerStyle: {
        width: "734px",
      },
      maskStyle: {
        background: "rgba(0,0,0,0)",
      },
      headerStyle: {
        display: "none",
      },
      bodyStyle: {
        padding: "0",
      },
      searchIpt: '',
      currentIpt: '',
      buttonList: [
        {
          name: '门店查询',
          path: '/dealer/storeSearch'
        },
        {
          name: '数据下载',
          path: '/dealer/storeCompetition'
        },
        {
          name: '竞品对标',
          path: '/dealer/regionContrast'
        },
        {
          name: '门店运营管理',
          path: '/dealer/storeManagement'
        },
        {
          name: '直播竞赛',
          path: '/dealer/storeRanking'
        },
        {
          name: '刷量监测',
          path: '/depthInspection/videoMonitoring'
        }
      ],
      mapList1: [],
      mapList2: [],
      mapList3: [],
    };
  },
  computed: {
    ...mapState({
      areaList: (state) => state.areaList,
    }),
  },
  watch: {
    areaList(val) {
      val.forEach((item, index) => {
        if ((index + 1) % 3 == 1) {
          this.mapList1.push(item)
        } else if ((index + 1) % 3 == 2) {
          this.mapList2.push(item)
        } else {
          this.mapList3.push(item)
        }
      })
    }
  },
  async created() {
    await this.getAreaAuthority()
  },
  methods: {
    ...mapActions(["getAreaAuthority"]),
    close() {
      this.$emit("close");
    },
    routerTo(path) {
      if (path) {
        this.$router.push({
          path: path
        })
      } else {
        this.$message.warning('当前页面还未公测')
      }
    },
    stringToObject(str) {
      return JSON.parse(str)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.area-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: red;
  z-index: 99999;
}
.font-color1 {
  @include font_color("font_color1");
}
.font-color2 {
  @include font_color("font_color2");
}
.font-color3 {
  @include font_color("font_color3");
}
.header {
  height: 60px;
  font-size: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  .icon1 {
    cursor: pointer;
  }
  .icon2 {
    margin: 0 8px 0 16px;
    font-size: 24px;
    @include font_color("font_color3");
  }
}
.container {
  padding: 0 24px;
  .tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 24px 0 0px;
    .button {
      margin-left: 8px;
      @include background_color("background_color33");
      border: none;
      @include font_color("font_color3");
    }
  }
  .button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 37px;
    span {
      display: inline-block;
      cursor: pointer;
      padding: 0px 19px;
      margin-top: 16px;
      margin-right: 16px;
      background: none;
      @include font_color("font_color3");
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color7");
    }
    span:last-child {
      margin-right: 0;
    }
    span:hover {
      @include font_color("font_color1");
      @include border_color("border_color11");
      @include background_color("background_color34");
    }
  }

  &-main {
    display: flex;
    flex-direction: row;
    &-col {
      width: 33.3%;
      display: flex;
      flex-direction: column;
      .item {
        margin-bottom: 53px;
        &-title {
          .icon {
            @include font_color("font_color3");
            font-size: 24px;
            margin-right: 8px;
          }
          font-size: 20px;
          @include font_color("font_color1");
        }
        &-children {
          margin-top: 16px;
          cursor: pointer;
          font-size: 14px;
          padding-left: 32px;
          @include font_color("font_color2");
          .tag {
            position: relative;
            top: -3px;
            left: 4px;
            display: inline-block;
            font-size: 12px;
            border-radius: 4px;
            height: 16px;
            line-height: 16px;
            padding: 0 4px;
            @include font_color("font_color1");
            @include background_color("background_color35");
          }
        }
        &-children:hover {
          @include font_color("font_color5");
        }
        &-children.active {
          @include font_color("font_color5");
        }
      }
    }
  }
}
</style>
