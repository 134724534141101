import TabsView from '@/layouts/tabs/TabsView'
import BlankView from '@/layouts/BlankView'
import PageView from '@/layouts/PageView'

// 路由配置
const options = {
  routes: [
    {
      path: '/login',
      name: '登录页',
      component: () => import('@/pages/login')
    },
    {
      path: '/icons',
      name: 'icon',
      component: () => import('@/pages/SvgIcons'),
    },
    {
      path: '/403',
      name: '403',
      component: () => import('@/pages/exception/403'),
    },
    {
      path: '*',
      redirect: '/404',
      name: '404',
      component: () => import('@/pages/exception/404'),
    },
    {
      path: '/',
      name: '首页',
      component: TabsView,
      redirect: '/login',
      children: [
        {
          path: '/bcPublicScreen',
          name: '奔驰-公共大屏',
          meta: {
            title: '奔驰-公共大屏',
            icon: 'table',
          },
          component: PageView,
          children: [
            {
              path: '/bcPublicScreen/brandSummary1',
              name: '品牌概述1',
              meta: {
                title: '品牌概述',
                icon: 'table'
              },
              component: () => import('@/pages/bcPublicScreen/brandSummary/brandSummary1.vue')
            },
            {
              path: '/bcPublicScreen/brandSummary2',
              name: '品牌概述2',
              meta: {
                title: '品牌概述',
                icon: 'table'
              },
              component: () => import('@/pages/bcPublicScreen/brandSummary/brandSummary2.vue')
            },
            {
              path: '/bcPublicScreen/dealerOfOneself',
              name: '矩阵分析',
              meta: {
                title: '矩阵分析',
                icon: 'table'
              },
              component: () => import('@/pages/bcPublicScreen/dealerOfOneself/dealerOfOneself.vue')
            }
          ]
        },
        {
          path: '/bcCoreScreen',
          name: '奔驰-核心大屏',
          meta: {
            title: '奔驰-核心大屏',
            icon: 'table',
          },
          component: PageView,
          children: [
            {
              path: '/bcCoreScreen/liveArea',
              name: '直播地图',
              meta: {
                title: '直播地图',
                icon: 'table'
              },
              component: () => import('@/pages/bcCoreScreen/liveArea/liveArea.vue')
            },
            {
              path: '/bcCoreScreen/brandCalendar',
              name: '直播日历',
              meta: {
                title: '直播日历',
                icon: 'table'
              },
              component: () => import('@/pages/bcCoreScreen/brandCalendar/index.vue')
            },
            {
              path: '/bcCoreScreen/worksList',
              name: '品牌官号作品榜',
              meta: {
                title: '品牌官号作品榜',
                icon: 'table'
              },
              component: () => import('@/pages/bcCoreScreen/worksList/worksList.vue')
            }
          ]
        }
      ]
    },
  ]
}

export default options
