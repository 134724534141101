// import { login as _login, logout, getInfo, getManufactorList, checkToken } from '@/api/user'
import api from '@/api/auth'
import { getToken, setToken, removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'
const { login, logOut, getAuthority, getAreaAuthority, getPrincipals,getSweepCode } = api
const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    list: [],
    areaList: [],
    principalsList: [],
    principalsLogo: '',
    routes: [],
    sweepLoading:false,
    ticketData:{}
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_LIST: (state, list) => {
    state.list = list
  },
  SET_AREA_LIST: (state, list) => {
    state.areaList = list
  },
  SET_PRINCIPALS: (state, list) => {
    state.principalsList = list
  },
  SET_PRINCIPAL_LOGO: (state, logo) => {
    state.principalsLogo = logo
  },
  SET_ROUTES: (state, routes) => {
    state.routes = routes
  },
  changeState(state, payload) {
    let {
        data,
        name
    } = payload;
    state[name] = data;
  }
}

const actions = {
  // 登录
  login({ commit }, userInfo) {
    const { username, password,grant_type,ticket,client_module_id } = userInfo
    return new Promise((resolve, reject) => {
      login({
        username: username.trim(),
        password: password,
        grant_type,
        ticket,
        client_module_id
      })
        .then(response => {
          const { data } = response
          if (data.code != 200) {
            reject(response)
          } else {
            if (data.data.token_head && data.data.token) {
              commit('SET_TOKEN', data.data.token_head + data.data.token)
              setToken(data.data.token_head + data.data.token)
              resolve(response)
            } else {
              let msg = 'modules/auth.js error: token is ' + data.data.token
              let errObj = {
                data: {
                  errcode: -5001,
                  errmsg: msg
                }
              }
              reject(errObj)
            }
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // 获取路由
  async logOut() {
    return logOut()
      .then((res) => {
        console.log('route', res)
      })
  },
  // 获取路由
  async getAuthority({ commit }) {
    return getAuthority()
      .then((res) => {
        console.log('route', res)
          commit('SET_LIST', res.data.data)
      })
  },
  // 获取全站地图路由
  async getAreaAuthority({ commit }) {
    return getAreaAuthority()
      .then((res) => {
        console.log('area-route', res)
          commit('SET_AREA_LIST', res.data.data)
      })
  },
  // 获取主体
  async getPrincipals({ commit }) {
    return getPrincipals()
      .then((res) => {
        console.log('getPrincipals', res)
        commit('SET_PRINCIPALS', res.data)
        commit('SET_NAME', res.data[0].principal_name)
        console.log(process.env)
        commit('SET_AVATAR', `${process.env.VUE_APP_LIVE_OSS_HOST}${res.data[0].logo}`)
      })
  },
  checkToken({ commit }, manufactor_id) {
    return new Promise((resolve, reject) => {
      checkToken({ manufactor_id: manufactor_id })
        .then(response => {
          const { data } = response
          console.log(data)
          if (data.token) {
            commit('SET_TOKEN', data.token)
            setToken(data.token)
            resolve()
          } else {
            let err = 'token is ' + data.token
            reject(err)
            throw new Error(err)
          }
        })
        .catch(error => {
          // console.error(error)
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(response => {
          const { data } = response

          if (!data) {
            reject('Verification failed, please Login again.')
          }

          const { name, avatar } = data.data
          commit('SET_NAME', name)
          commit('SET_AVATAR', avatar)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // get manufactor list
  getManufactorList({ commit, state }) {
    return new Promise((resolve, reject) => {
      getManufactorList(state.token)
        .then(response => {
          const { data } = response

          if (!data) {
            reject('Verification failed, please Login again.')
          }
          commit('SET_LIST', data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then((res) => {
          console.log(res)
          if ((res.data.code == "200" && res.data.msg == "success") || res.data.errcode == 1001) {
            removeToken() // must remove  token  first
            // resetRouter()
            commit('RESET_STATE')
            resolve()
          } else {
            this.$message.error('退出失败，请联系管理员')
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },

  // 获取二维码接口
  getSweepCode({commit}){
    commit('changeState', {
        name: 'sweepLoading',
        data: true
    })
    return getSweepCode().then(res=>{
      console.log('返回的二维码',res)
      if(res.data.code==200){
        commit('changeState', {
          name: 'ticketData',
          data: res.data.data
        })
      }
      commit('changeState', {
        name: 'sweepLoading',
        data: false
      })
    }).catch(()=>{
      commit('changeState', {
        name: 'sweepLoading',
        data: true
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
