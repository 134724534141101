/*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
   * */
  function formatNumber(number, decimals, dec_point, thousands_sep, roundtag) {
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    roundtag = roundtag || "ceil"; //"ceil","floor","round"
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      s = '',
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec);
        return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k;
      };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }
 
  /* 
  * 判断是否为数组
  */
  function judgeObject(arg){
    return Object.prototype.toString.call(arg)=='[object Array]';
  }

  /**
   * 空数据与string类型处理
   */
  function judgeNum(value){
    if(value){
      return Number(value)
    }else{
      return false
    }
  }
  /* 
  *小数处理
  * number => 传入数字
  * point => 保留几位小数 (默认为1)
  * zeroFill => 是否去掉补零 （Boolean值，默认为false）
  */
  function disposeDecimal(number,point,zeroFill){
    let num = number
     if(num){
        num = Number(number);
        if(typeof num === 'number' && num != NaN){
            num = point ? num.toFixed(point) : num.toFixed(1);
            if(zeroFill){
                return num
            }else{
                return parseFloat(num)
            }
        }
        return 0
     }
     return 0;
  }

  // 补零颗粒
  function disposeZero(val) {
    return disposeDecimal(val, 4, true)
  }

  /* 
  *数据处理
  *value => 传入数据
  * type => 处理类型(0:补亿或万（可不传，转为零)；1:补逗号；）
  * point => 保留几位小数 (默认为2)
  * zeroFill => 是否去掉补零 （Boolean值，默认为false）
  */
  function changeNum(value,point,zeroFill,type){
    if (typeof(value) != 'number') {
      return '-'
    }

    let num = disposeDecimal(value,point,zeroFill);
    let types = type?type:0;
    switch (types) {
        case 0:
            // num = disposeDecimal(value,point,zeroFill);
            if(Math.abs(num) >= 100000000){
                num = `${disposeDecimal(num/100000000,point,zeroFill)}亿`
            }else if(Math.abs(num) >= 10000){
                num = `${disposeDecimal(num/10000,point,zeroFill)}w`
            }   
            break;
        case 1:
            num = Number.parseFloat(num).toLocaleString('en-US')
        default:
            break;
    }
    return num
  }
  function changeNum_point2(value,point = 2,zeroFill,type){
    if (typeof(value) != 'number') {
      return '-'
    }

    let num = disposeDecimal(value,point,zeroFill);
    let types = type?type:0;
    switch (types) {
        case 0:
            // num = disposeDecimal(value,point,zeroFill);
            if(Math.abs(num) >= 100000000){
                num = `${disposeDecimal(num/100000000,point,zeroFill)}亿`
            }else if(Math.abs(num) >= 10000){
                num = `${disposeDecimal(num/10000,point,zeroFill)}w`
            }   
            break;
        case 1:
            num = Number.parseFloat(num).toLocaleString('en-US')
        default:
            break;
    }
    return num
  }

  function changeNum2(value,point,zeroFill,type){
    if (typeof(value) != 'number') {
      return '-'
    }
    if (value > 0 && value < 1) {
      return '<1'
    }
    let num = disposeDecimal(value,point,zeroFill);
    let types = type?type:0;
    switch (types) {
        case 0:
            // num = disposeDecimal(value,point,zeroFill);
            if(Math.abs(num) >= 100000000){
                num = `${disposeDecimal(num/100000000,point,zeroFill)}亿`
            }else if(Math.abs(num) >= 10000){
                num = `${disposeDecimal(num/10000,point,zeroFill)}w`
            }   
            break;
        case 1:
            num = Number.parseFloat(num).toLocaleString('en-US')
        default:
            break;
    }
    return num
  }

  export function changeNumNew(value,point,zeroFill,type){
    if (typeof(value) != 'number') {
      return '--'
    }
  
    let num = disposeDecimal(value,point,zeroFill);
    let types = type?type:0;
    switch (types) {
        case 0:
            // num = disposeDecimal(value,point,zeroFill);
            if(Math.abs(num) >= 100000000){
                num = `${disposeDecimal(num/100000000,point,zeroFill)}`
            }else if(Math.abs(num) >= 10000){
                num = `${disposeDecimal(num/10000,point,zeroFill)}`
            }   
            break;
        case 1:
            num = Number.parseFloat(num).toLocaleString('en-US')
        default:
            break;
    }
    return num
  }
  
  /**
   * 数据后缀生成
   */
  export function generateNumSuffix(value) {
    let suffix = ''
  
    if (typeof(value) !== 'number') {
      return suffix
    }
  
    let num = disposeDecimal(value)
  
    if (Math.abs(num) >= 100000000) {
      suffix = `亿`
    } else if (Math.abs(num) >= 10000) {
      suffix = `w`
    }
  
    return suffix
  }

  // 添加逗号颗粒
  function numCode(val){
   return changeNum(val,'','',1)
  }

  // 添加%
  function numRate(val, base = ''){
    return disposeDecimal(val*100, base, false) + '%'
  }
  // 添加%
  function numRate2(val, base = 2){
    return disposeDecimal(val*100, base, false) + '%'
  }
  // 添加%
  function numRateAbs(value, point){
    if (typeof(value) != 'number') {
      return '-'
    }
    if (Math.abs(value * 100).toFixed(point || 0) < 1) {
      return '<1%'
    }
    return Math.abs(value * 100).toFixed(point || 0) + '%'
  }
  // 添加%
  function numRateAbs2(value, point = 2){
    if (typeof(value) != 'number') {
      return '-'
    }
    if (Math.abs(value * 100).toFixed(point || 0) < 1) {
      return '<1%'
    }
    return Math.abs(value * 100).toFixed(point || 0) + '%'
  }

// 不加%
export function numRateNew(val, base = ''){
  return disposeDecimal(val*100, base, false)
}

  // 秒 => 分
  function toMin(time){
    if (typeof(time) != 'number') {
      return '-'
    }
    return (time/60).toFixed(0) + 'min'
  }

  // 时长：天时分秒计算
  function disposeLongTime(value){
    let result = judgeNum(value)
    if(result){
      let d = parseInt(result / ( 60 * 60 * 24));
      let h = parseInt((result % ( 60 * 60 * 24)) / ( 60 * 60));
      let m = parseInt((result % ( 60 * 60)) /  60);
      let s = result %  60 ;
      if(d>0){
        return d + " 天 " + h + " 小时 " + m + " 分钟 " + s + " 秒 ";
      }
      if(d==0&&h>0){
        return h + " 小时 " + m + " 分钟 " + s + " 秒 ";
      }
      if(d==0&&h==0&&m>0){
        return  m + " 分钟 " + s + " 秒 ";
      }
      if(d==0&&h==0&&m==0&&s>0){
        return  s + " 秒 ";
      }
    
    }else{
      return '——'
    }
  }
  // 时长：天时分秒计算
  function disposeLongTime_m(value){
    let result = judgeNum(value)
    if(result){
      let d = parseInt(result / ( 60 * 60 * 24));
      let h = parseInt((result % ( 60 * 60 * 24)) / ( 60 * 60));
      let m = parseInt((result % ( 60 * 60)) /  60);
      let s = result %  60 ;
      if(d>0){
        return d + " 天 " + h + " 时 " + m + " 分 ";
      }
      if(d==0&&h>0){
        return h + " 时 " + m + " 分 ";
      }
      if(d==0&&h==0&&m>0){
        return  m + " 分 " + s + " 秒 ";
      }
      if(d==0&&h==0&&m==0&&s>0){
        return  s + " 秒 ";
      }
    
    }else{
      return '——'
    }
  }
  // 时长：天时分秒计算--统计类
  function disposeLongTime2(value){
    let result = judgeNum(value)
    if(result){
      let d = parseInt(result / ( 60 * 60 * 24));
      let h = parseInt((result % ( 60 * 60 * 24)) / ( 60 * 60));
      let m = parseInt((result % ( 60 * 60)) /  60);
      let s = result %  60 ;
      if(d>0){
        return d + " 天 " + h + " 小时 ";
      }
      if(d==0&&h>0){
        return h + " 小时 ";
      }
      if(d==0&&h==0&&m>0){
        return  '<1小时';
      }
      if(d==0&&h==0&&m==0&&s>0){
        return  '<1小时';
      }
    
    }else{
      return '——'
    }
  }
  // 时长：xx.xx小时
  function disposeLongTime_h(value){
    let result = judgeNum(value)
    if(result){
      let h = (result / (60 * 60)).toFixed(1)
      return h + ' 小时'
    }else{
      return '——'
    }
  }
  // {{ 1232134 | changeNum }} 万位亿位处理，保留两位小数,不补零
  // {{ 1232134 | numCode }} 添加逗号，保留两位小数，不补零
  // {{ 1232134 | disposeDecimal }} 保留两位小数，不补零
  // {{ 1232134 | disposeZero }} 保留四位小数，补零
  // {{ this.filtrate.changeNum(439942394,2,true) }} 调用函数传参
  function stringIsNull(value) {
    if (value) {
      return value
    } else {
      return '-'
    }
  }
  function is_three_hundred_top(value) {
    if (value && value == 1) {
      return '是'
    } else {
      return '否'
    }
  }

export function formatDuration(number) {
  if (!number || number === 0) {
    return "0时0分";
  } else {
    let h = (number / (60 * 60)) | 0;
    let m = ((number % (60 * 60)) / 60) | 0;
    return `${h}时${m}分`;
  }
}

export default {
  formatNumber,
  judgeObject,
  disposeDecimal,
  changeNum,
  changeNum2,
  changeNum_point2,
  changeNumNew,
  generateNumSuffix,
  disposeZero,
  numCode,
  numRate,
  numRateAbs,
  numRate2,
  numRateAbs2,
  numRateNew,
  toMin,
  disposeLongTime,
  disposeLongTime_m,
  disposeLongTime2,
  disposeLongTime_h,
  stringIsNull,
  is_three_hundred_top,
  formatDuration
}
