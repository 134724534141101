import axios from 'axios'
import { message } from 'ant-design-vue'
import store from '@/store'
import { getToken, getUserId, getPrincipalId } from '@/utils/auth'
import _ from 'lodash'

var canError = true
function errLog(msg) {
  canError = false
  message.error({
    content: msg,
    duration: 5
  })
}

function getBaseUrl(serviceName) {
  let url = ''
  switch (serviceName) {
    case 'cas':
      url = process.env.VUE_APP_CAS_API
      break;
    case 'agg':
      url = process.env.VUE_APP_OPENDATA_AGG_API
      break;
    case 'mqtt':
      url = process.env.VUE_APP_OPENDATA_MQTT_API
      break;
    case 'douyin':
      url = process.env.VUE_APP_OPENDATA_API
      break;
    case 'kuaishou':
      url = process.env.VUE_APP_OPENDATA_KS_API
      break;
    case 'dongchedi':
      url = process.env.VUE_APP_OPENDATA_DCD_API
      break;
    default:
      url = process.env.VUE_APP_OPENDATA_API
      break;
  }
  return url
}
window.errLog = errLog

export default class {
  constructor(config) {
    const defaultConfig = {
      timeout: 1000 * 30,
      needTocken: true,
      // baseURL: _.get(config, 'serviceName') == 'cas' ? process.env.VUE_APP_CAS_API : _.get(config, 'serviceName') == 'kuaishou' ? process.env.VUE_APP_OPENDATA_KS_API : process.env.VUE_APP_OPENDATA_API,
      baseURL: getBaseUrl(_.get(config, 'serviceName')),
      needMessage: true
    }
    const _config = Object.assign({}, defaultConfig, config)
    this._axios = axios.create({
      baseURL: _config.baseURL,
      timeout: _config.timeout
    })

    this._axios.interceptors.request.use(
      requestConfig => {
        
        if (_config.needTocken) {
          if (!getToken()) {
            window.location = '/login'
            return false
          }
          requestConfig.headers['Authorization'] = getToken()
          requestConfig.headers['p_id'] = getPrincipalId()
          requestConfig.headers['user_id'] = getUserId()
          requestConfig.headers['client_id'] = 'new-jjt'
          
        }
        return requestConfig
      },
      error => {
        console.log(error) // for debug
        return Promise.reject(error)
      }
    )

    // response interceptor
    this._axios.interceptors.response.use(
      response => {
        // console.log('response', response)
       
        return response
      },
      error => {
        console.log('error', error.response)
        let msg = '网络错误'
        if (error.response && error.response.status == 401) {
          msg = 'token失效,请重新登录'
          if (error.response.data.code == 50101401) {
            msg = '已在另外一个设备登录，被迫下线。请知悉'
          }
          window.location.href = '/login'
        }
        if (canError) {
          errLog(msg)

          setTimeout(function() {
            canError = true
          }, 5000)
        }
        
        return Promise.reject(error)
      }
    )
  }

  getAxios() {
    return this._axios
  }
  getBaseUrl(serviceName) {
    return getBaseUrl(serviceName)
  }

}
