// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView'),
  page: () => import('@/layouts/PageView')
}

// 路由组件注册
const routerMap = {
  login: {
    authority: '*',
    path: '/login',
    component: () => import('@/pages/login')
  },
  icon:{
    name:'icon',
    path:'icons',
    component: () => import('@/pages/SvgIcons/index')
  },
  root: {
    path: '/',
    name: '首页',
    redirect: '/login',
    component: view.tabs
  },
  exp403: {
    authority: '*',
    name: 'exp403',
    path: '403',
    component: () => import('@/pages/exception/403')
  },
  exp404: {
    name: 'exp404',
    path: '404',
    component: () => import('@/pages/exception/404')
  },
  exp500: {
    name: 'exp500',
    path: '500',
    component: () => import('@/pages/exception/500')
  },
  bcPublicScreen: {
    name: '奔驰-公共大屏',
    icon: 'form',
    component: view.page,
  },
  brandSummary1: {
    path: 'brandSummary1',
    name: '品牌概述1',
    icon: 'form',
    component: () => import('@/pages/bcPublicScreen/brandSummary/brandSummary1.vue')
  },
  brandSummary2: {
    path: 'brandSummary2',
    name: '品牌概述2',
    icon: 'form',
    component: () => import('@/pages/bcPublicScreen/brandSummary/brandSummary2.vue')
  },
  dealerOfOneself: {
    path: 'dealerOfOneself',
    name: '区域横向对比',
    icon: 'form',
    component: () => import('@/pages/bcPublicScreen/dealerOfOneself/dealerOfOneself.vue')
  },
  bcCoreScreen: {
    name: '奔驰-核心大屏',
    icon: 'form',
    component: view.page,
  },
  liveArea: {
    path: 'liveArea',
    name: '直播地图',
    icon: 'form',
    component: () => import('@/pages/bcCoreScreen/liveArea/liveArea.vue')
  },
  brandCalendar: {
    path: 'brandCalendar',
    name: '直播日历',
    icon: 'form',
    component: () => import('@/pages/bcCoreScreen/brandCalendar/index.vue')
  },
  worksList: {
    path: 'worksList',
    name: '品牌官号作品榜',
    icon: 'form',
    component: () => import('@/pages/bcCoreScreen/worksList/worksList.vue')
  }
}
export default routerMap

