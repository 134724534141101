<template>
  <a-config-provider :locale="locale" :get-popup-container="popContainer">
    <router-view />
  </a-config-provider>
</template>

<script>
import { enquireScreen } from "./utils/util";
import { mapState, mapMutations } from "vuex";
import themeUtil from "@/utils/themeUtil";
import { getI18nKey } from "@/utils/routerUtil";

export default {
  name: "App",
  data() {
    return {
      locale: {},
    };
  },
  created() {
    this.setHtmlTitle();
    this.setLanguage(this.lang);
    enquireScreen((isMobile) => this.setDevice(isMobile));

    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("store")) {
      let state = Object.assign(
        {},
        this.$store.state,
        JSON.parse(decodeURIComponent(localStorage.getItem("store")))
      );

      this.$store.replaceState(state);
    }
    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      localStorage.setItem(
        "store",
        encodeURIComponent(JSON.stringify(this.$store.state))
      );
    });
  },
  mounted() {
    this.setWeekModeTheme(this.weekMode);
  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val);
    },
    lang(val) {
      this.setLanguage(val);
      this.setHtmlTitle();
    },
    $route() {
      this.setHtmlTitle();
    },
    "theme.mode": function (val) {
      let closeMessage = this.$message.loading(
        `您选择了主题模式 ${val}, 正在切换...`
      );
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage);
    },
    "theme.color": function (val) {
      let closeMessage = this.$message.loading(
        `您选择了主题色 ${val}, 正在切换...`
      );
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage);
    },
    layout: function () {
      window.dispatchEvent(new Event("resize"));
    },
  },
  computed: {
    ...mapState("setting", ["layout", "theme", "weekMode", "lang"]),
  },
  methods: {
    ...mapMutations("setting", ["setDevice"]),
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add("week-mode");
      } else {
        document.body.classList.remove("week-mode");
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang;
      switch (lang) {
        case "CN":
          this.locale = require("ant-design-vue/es/locale-provider/zh_CN").default;
          break;
        case "HK":
          this.locale = require("ant-design-vue/es/locale-provider/zh_TW").default;
          break;
        case "US":
        default:
          this.locale = require("ant-design-vue/es/locale-provider/en_US").default;
          break;
      }
    },
    setHtmlTitle() {
      const route = this.$route;
      const key =
        route.path === "/"
          ? "home.name"
          : getI18nKey(route.matched[route.matched.length - 1].path);
      document.title = process.env.VUE_APP_NAME + " | " + this.$t(key);
    },
    popContainer() {
      return document.getElementById("popContainer");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_handle.scss";
::selection {
  background: #0291d3!important;
}
::-webkit-scrollbar {
  width: 4px;
}
ul::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #565D72;
}
::-webkit-scrollbar-corner {
  @include background_color("background_color42");
}
::-webkit-scrollbar-track {
  border-radius: 0;
  @include background_color("background_color5");
}
// 日历弹窗样式更改
.ant-calendar-picker-container {
  
  .ant-calendar {
    border: none;
    border-radius: 16px;
    overflow: hidden;
    @include box_shadow("box_shadow4");
    @include background_color("background_color51");
  }
  .ant-calendar-month-panel,
  .ant-calendar-year-panel,
  .ant-calendar-decade-panel {
    @include background_color("background_color51");
  }
  .ant-calendar-month-panel-header,
  .ant-calendar-year-panel-header {
    @include background_color("background_color51");
  }
  .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-month,
  .ant-calendar-decade-panel-decade,
  .ant-calendar-decade-panel-century {
    @include font_color("font_color21");
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
  .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
    @include font_color("font_color17");
    @include background_color("background_color13");
    border-radius: 8px;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::before, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::before, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::before, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::after, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::after, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::after,
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::before, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::before, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::before, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::after, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::after, 
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::after {
    @include border_color("border_color17");
  }
  .ant-calendar-month-panel-selected-cell.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-selected-cell.ant-calendar-year-panel-cell-disabled .ant-calendar-year-panel-year,
  .ant-calendar-decade-panel-selected-cell.ant-calendar-decade-panel-cell-disabled .ant-calendar-decade-panel-decade {
    background: none!important;
  }
  .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
  .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-cell-disabled .ant-calendar-year-panel-year,
  .ant-calendar-year-panel-cell-disabled .ant-calendar-year-panel-year:hover {
    background: none!important;
    @include font_color("font_color3");
  }
  .ant-calendar-month-panel-year-select-content,
  .ant-calendar-year-panel-year {
    @include font_color("font_color21");
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
  .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
    @include font_color("font_color21");
  }
  .ant-calendar-month-panel-header,
  .ant-calendar-year-panel-header,
  .ant-calendar-decade-panel-header {
    border: none;
  }
  .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-year:hover,
  .ant-calendar-decade-panel-decade:hover {
    @include background_color("background_color13");
    @include font_color("font_color25");
    border-radius: 8px;
  }
  .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover,
  .ant-calendar-year-panel-year:hover,
  .ant-calendar-decade-panel-decade:hover {
    background: none!important;
  }
  .ant-calendar-header {
    @include font_color("font_color20");
    @include background_color("background_color62");
  }
  .ant-calendar-header .ant-calendar-century-select, .ant-calendar-header .ant-calendar-decade-select, .ant-calendar-header .ant-calendar-year-select, .ant-calendar-header .ant-calendar-month-select {
    @include font_color("font_color21");
  }
  .ant-calendar-body {
    @include font_color("font_color21");
    @include background_color("background_color1");
  }
  .ant-calendar-date {
    @include font_color("font_color21");
  }
  .ant-calendar-header {
    // @include border_color("border_color6");
  }
  .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
    @include background_color("background_color13");
  }
  .ant-calendar-picker-container .ant-calendar-selected-day .ant-calendar-date {
    @include font_color("font_color3");
  }
  .ant-calendar-week-number .ant-calendar-body tr:hover,
  .ant-calendar-date:hover {
    @include background_color("background_color13");
    @include font_color("font_color25");
  }
  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, 
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date,
  .ant-calendar-range .ant-calendar-in-range-cell::before {
    @include background_color("background_color34");
    @include font_color("font_color25");
    height: 24px;
    margin-top: -1px;
  }
  .ant-calendar-cell.ant-calendar-in-range-cell .ant-calendar-date {
    @include font_color("font_color25");
  }
  .ant-calendar-today .ant-calendar-date {
    @include border_color("border_color11");
  }
  .ant-calendar-disabled-cell .ant-calendar-date {
    background: none;
    @include font_color("font_color3");
  }
  .ant-calendar-disabled-cell .ant-calendar-date:hover {
    background: none!important;
  }
  .ant-calendar-range .ant-calendar-month-panel-body, .ant-calendar-range .ant-calendar-year-panel-body, .ant-calendar-range .ant-calendar-decade-panel-body {
    @include border_color("border_color18");
  }
  .ant-calendar-range .ant-calendar-body {
    @include border_color("border_color18");
    @include background_color("background_color51");
  }
  .ant-calendar-input-wrap {
    @include background_color("background_color51");
    @include font_color("font_color21");
    @include border_color("border_color18");
  }
  .ant-calendar-range .ant-calendar-time-picker-input,
  .ant-calendar-range .ant-calendar-input {
    @include background_color("background_color51");
    @include font_color("font_color22");
  }
  .ant-calendar-range-middle {
    @include font_color("font_color21");
  }
  
}
// 下拉框弹窗样式修改

#popContainer {
  .ant-select-dropdown {
    @include background_color("background_color51");
    border-radius: 16px;
    overflow: hidden;
    @include box_shadow("box_shadow4");
  }
  .ant-select-dropdown-menu {
    padding: 0;
  }
  .ant-select-dropdown-menu-item {
    padding: 11px 12px;
    height: 44px;
    @include font_color("font_color2");
  }
  .ant-select-dropdown-menu-item-selected {
    @include background_color("background_color5");
    @include font_color("font_color26");
  }
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    @include background_color("background_color5");
    @include font_color("font_color26");
  }
  .ant-select-selection__placeholder, 
  .ant-select-search__field__placeholder {
    @include font_color("font_color38");
  }
  .ant-select-clear-icon {
    @include background_color("background_color50");
    @include font_color("font_color22");
  }
  .modal .ant-select-clear-icon {
    @include background_color("background_color1");
  }
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  @include background_color("background_color1");
}
.ant-spin-container::after {
    @include background_color("background_color5");
}
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td, 
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td, 
.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td, 
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  @include background_color("background_color42");
}
.ant-table-fixed-left, .ant-table-fixed-right {
  height: 100%;
}
.ant-table-fixed-left table, .ant-table-fixed-right table {
  @include background_color("background_color1");
}
.ant-table-tbody>tr.ant-table-row .table-show-icon {
  display: none;
}
.ant-table-tbody>tr.ant-table-row:hover .table-show-icon {
  display: inline-block;
}
.ant-table-tbody>tr.ant-table-row:hover .table-show-icon.active {
  display: none;
}
.hover-table{

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 7px 7px;
    border: none;
    @include border_color("border_color6");
  }
  .ant-table-thead > tr > th {
    @include background_color("background_color2");
    @include font_color("font_color2");
  }
  .ant-table-fixed-header
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body {
    @include background_color("background_color5");
  }
  .ant-table {
    @include font_color("font_color3");
    @include background_color("background_color1");
  }
  .ant-table-header {
    background: none !important;
  }
  .ant-table-placeholder {
    @include font_color("font_color3");
    border: none;
    @include background_color("background_color1");
  }
  .table-row-active {
    @include background_color("background_color42");
  }
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    @include background_color("background_color42");
  }
  .ant-table-tbody .rowActive {
    @include background_color("background_color42");
  }
}
.ant-drawer-content {
  @include font_color("font_color1");
  opacity: 0.9;
}
.ant-drawer-wrapper-body {
  @include background_color("background_color28");
}
.ant-drawer {
  .area-input .ant-input {
    @include background_color("background_color33");
    @include font_color("font_color2");
    @include border_color("border_color1");
  }
}

//dropdown
.aft-menu.ant-dropdown-menu {
  @include background_color("background_color51");
  padding: 0!important;
  border-radius: 16px!important;
}
.aft-menu.ant-dropdown-menu .ant-dropdown-menu-item:hover, 
.aft-menu.ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
  @include background_color("background_color5");
  @include font_color("font_color26");
}
.ant-dropdown-menu {
  @include background_color("background_color2");
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  @include font_color("font_color2");
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  @include background_color("background_color6");
}
.ant-dropdown-menu-item-divider {
  margin: 0!important;
  @include background_color("background_color67");
}
// 表格阴影
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left,
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  @include box_shadow("box_shadow3");
}
.ant-table-fixed-left {
  @include box_shadow("box_shadow1");
}
.ant-table-fixed-right {
  @include box_shadow("box_shadow2");
}

.icon-select .ant-select-search--inline .ant-select-search__field {
  padding-left: 20px;
  // @include background_color("background_color47");

}
.ant-modal-content {
  .ant-form-item {
    margin-bottom: 14px;
  }
  .ant-form-explain {
    margin-left: 12px;
  }
  @include background_color("background_color2");
  border-radius: 26px!important;
  overflow: hidden;
  .ant-modal-confirm-body {
    .ant-modal-confirm-title,
    .ant-modal-confirm-content {
      @include font_color("font_color20");
    }
  }

  .ant-input {
    @include background_color("background_color1");
    @include font_color("font_color20");
    @include border_color("border_color13");
  }
  .aft-input.modal.ant-input {
    padding: 4px 20px;
  }
  textarea.ant-input {
    border-radius: 16px!important;
  }
}

  // -- 月份日历选择相关 start --
  .aft-month-picker.default.ant-calendar-picker,
  .aft-range-picker.default.ant-calendar-picker {
    .ant-input {
      height: 40px;
      border: none;
      border-radius: 8px;
      font-size: 12px;
      @include font_color("font_color20");
    }
    .ant-calendar-range-picker-separator {
      @include font_color("font_color20");
      line-height: 30px;
    }
  }
  
  .modal {
    .ant-calendar-range-picker-input {
      border-radius: 8px!important;
      &::placeholder {
        @include font_color("font_color38");
      }
      &:focus {
        box-shadow: none;
      }
    }
    &.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
      box-shadow: none!important;
      -webkit-box-shadow: none!important;
    }
    .ant-calendar-picker-input {
      border-radius: 8px!important;
    }
    
    .ant-calendar-picker-clear {
      background: none;
      @include background_color("background_color1");
      @include font_color("font_color3");
    }
    .ant-calendar-picker-icon {
      @include font_color("font_color3");
    }
    .ant-calendar-range-picker-separator {
      @include font_color("font_color20");
      line-height: 30px;
    }
    
  }
  .ant-form-item-label > label {
      @include font_color("font_color23");
  }
  // -- 月份日历选择相关 end -- 

  // -- checkbox相关 start --
  .aft-checkbox.modal.ant-checkbox-group {
    .ant-checkbox-inner {
      width: 22px;
      height: 22px;
      border-radius: 8px;
      border: none;
      @include background_color("background_color1");
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      @include background_color("background_color34");
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      @include border_color("border_color20");
      left: 31%;
    }
    .ant-checkbox-checked::after {
      border: none;
    }
    .ant-checkbox-wrapper {
      @include font_color("font_color23");
      font-size: 14px;
    }
  }
  // -- checkbox相关 end -- 

  // -- 下拉框相关 start --
  .aft-select.default.ant-select {
    @include font_color("font_color28");
    .ant-select-selection {
      @include background_color("background_color50");
      border: none!important;

    }
    .ant-select-selection-selected-value {
      @include font_color("font_color21");
    }

  }
  .aft-select.modal.ant-select {
    @include font_color("font_color28");
    .ant-select-selection {
      @include background_color("background_color1");
      border-radius: 8px;
      border: none!important;

    }
    .ant-select-selection-selected-value {
      @include font_color("font_color21");
    }

    .ant-select-arrow {
      @include font_color("font_color22");
    }
    .ant-select-selection--single {
      height: 40px;
    }
    .ant-select-selection__rendered {
      line-height: 38px;
    }
    .ant-select-selection__placeholder, 
    .ant-select-search__field__placeholder {
      @include font_color("font_color38");
      padding-left: 10px;
    }
    .ant-select-arrow {
      @include font_color("font_color22");
    }
    .ant-select-selection__rendered {
      margin-left: 20px;
    }

    .ant-select-focused .ant-select-selection, 
    .ant-select-selection:focus, 
    .ant-select-selection:active {
      box-shadow: none;
    }

  }

  // -- 展开时
  .aft-select.default.ant-select.ant-select-open {
    @include font_color("font_color20");
    .ant-select-selection {
      @include background_color("background_color42");
    }
    .ant-select-selection-selected-value {
      @include font_color("font_color20");
    }
  }
  // -- 下拉框相关 end --

  // -- input相关 start --
  .aft-search.ant-input-search.ant-input-affix-wrapper {
    >input {
      height: 40px;
      border: none!important;
      @include font_color("font_color21");
      outline: medium;
      @include background_color("background_color50");
    }
    >input:focus {
      @include font_color("font_color20");
      @include background_color("background_color5");
    }
  }
  .aft-input.modal.ant-input {
    border: none;
    @include background_color("background_color1");
    border-radius: 8px;
    &::placeholder {
      @include font_color("font_color38");
    }
    &:focus {
      box-shadow: none;
    }
  }
  // -- input相关 end --



</style>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-menu-vertical > .ant-menu-item,
/deep/ .ant-menu-vertical-left > .ant-menu-item,
/deep/ .ant-menu-vertical-right > .ant-menu-item,
/deep/ .ant-menu-inline > .ant-menu-item,
/deep/ .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
/deep/ .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
/deep/ .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
/deep/ .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 44px;
  line-height: 44px;
}
.ant-layout /deep/ {
  // 最新版本样式 -- start --------------------------------------------
  // 侧边栏
  .ant-layout-sider-children {
    overflow-y: auto;
    height: calc(100vh - 76px);
  }
  // -- table相关 start --
  .aft-table.default.ant-table-wrapper {
    border-radius: 16px;
    overflow: hidden;
    .ant-table-tbody > tr,
    .ant-table-tbody > tr > td{
      border-top: 1px solid #323744;
      @include border_color("border_color16");
      @include font_color("font_color21");
    }
    .ant-table-thead > tr > th {
      @include background_color("background_color5");
      @include font_color("font_color21");
    }
    .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
      @include background_color("background_color42");
      @include font_color("font_color20");
    }
    .ant-table-tbody .rowActive {
      @include background_color("background_color42");
    }
    .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
      margin-top: -0.55em;
    }
  }
  // -- table相关 end --
  // 最新版本样式 -- end --------------------------------------------

  ::-webkit-scrollbar {
    width: 4px;
  }
  ul::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #565D72;
  }
  ::-webkit-scrollbar-corner {
    @include background_color("background_color42");
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
    @include background_color("background_color5");
  }
  .ant-layout.ant-layout-has-sider {
    @include background_color("background_color45");
  }
  .admin-layout-content {
    @include background_color("background_color1");
    // border-radius: 26px 26px 0 0;
    // border-right: 14px solid rgba(0, 0, 0, 0);
    // @include border_color("border_color19");
  }
  .ant-layout-sider {
    @include background_color("background_color45");
  }

  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    @include background_color("background_color45");
    @include font_color("font_color2");
  }
  .ant-menu-dark .ant-menu-item:hover,
  .ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-submenu-active,
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-submenu-title:hover,
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    @include background_color("background_color45");
    @include font_color("font_color2");
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    @include background_color("background_color15");
    @include font_color("font_color17");
    border-left: 4px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    width: calc(100% - 32px);
    border-radius: 8px;
    // @include border_color("border_color4");
  }
  .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    border-left: 4px solid rgba(0, 0, 0, 0);
    padding-left: 28px!important;
    width: calc(100% - 32px);
    border-radius: 8px;
    position: relative;
    left: 16px;
  }
  .ant-menu-dark .ant-menu-item-selected > a,
  .ant-menu-dark .ant-menu-item-selected > a:hover {
    @include font_color("font_color17");
    font-weight: bold;
  }
  .ant-input {
    @include background_color("background_color2");
    @include font_color("font_color2");
    @include border_color("border_color5");
  }
  .ant-input-affix-wrapper .ant-input-prefix, 
  .ant-input-affix-wrapper .ant-input-suffix {
    @include font_color("font_color3");
  }
  .ant-input-search-icon {
    @include font_color("font_color15");
  }
  .date-container .ant-input {
    background: none !important;
    // @include font_color("font_color3");
    border: none !important;
  }
  .ant-pagination-item a,
  .ant-pagination-prev a,
  .ant-pagination-next a {
    @include font_color("font_color3");
  }
  .ant-pagination-item-active {
    background: none;
    border: none;
    a {
      @include font_color("font_color5");
    }
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, 
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    @include font_color("font_color5");
  }
  .ant-pagination-item-ellipsis {
    @include font_color("font_color5");
  }
  .ant-calendar-picker-clear {
    background: none;
    @include background_color("background_color2");
    @include font_color("font_color3");
  }
  .ant-calendar-picker-icon {
    @include font_color("font_color3");
  }
  .ant-table-placeholder {
    @include font_color("font_color3");
    border: none;
  }
  .ant-table-thead > tr,
  .ant-table-tbody > tr {
    border-radius: 4px!important;
    @include background_color("background_color2");
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 7px 7px;
    border: none;
    // @include border_color("border_color6");
  }
  .ant-table-thead > tr > th {
    @include background_color("background_color5");
    @include font_color("font_color22");
    height: 50px;
    font-size: 12px;
  }
  .ant-table-header {
    background: none !important;
  }
  .table2 {
    .ant-table-thead > tr > th {
      @include background_color("background_color2");
    }
    .ant-table-fixed-header
      > .ant-table-content
      > .ant-table-scroll
      > .ant-table-body {
      @include background_color("background_color5");
    }
    .ant-table {
      @include font_color("font_color3");
    }
  }
  .table-row-active {
    @include background_color("background_color42");
  }
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    @include background_color("background_color42");
  }
  .ant-table-tbody .rowActive {
    @include background_color("background_color42");
  }

  // tabs

  .ant-tabs {
    @include font_color("font_color3");
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab:hover {
    @include font_color("font_color1");
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 0;
  }
  .ant-tabs-ink-bar {
    @include background_color("background_color15");
  }
  .ant-tabs-bar {
    border: none;
  }
  .ant-tabs-nav-container {
    @include background_color("background_color16");
    padding: 0 16px;
    border-radius: 4px;
  }
  .no-background .ant-tabs-nav-container {
    background: none !important;
  }
  .no-background .ant-tabs-ink-bar {
    background: none !important;
  }

  // 按钮样式修改
  .ant-radio-button-wrapper {
    @include background_color("background_color2");
    @include border_color("border_color1");
    @include font_color("font_color3");
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    @include background_color("background_color4");
    @include font_color("font_color1");
    @include border_color("border_color1");
    box-shadow: none !important;
  }

  // 下拉框
  .ant-select-selection {
    @include background_color("background_color61");
    @include border_color("border_color15");
    border-radius: 8px;
  }
  .ant-select-selection--multiple .ant-select-selection__choice {
    @include font_color("font_color3");
    @include background_color("background_color21");
    border: none;
  }
  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    @include font_color("font_color3");
  }
  .ant-select-selection--multiple > ul > li, 
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-top: 7px;
  }
  .ant-select-selection-selected-value {
    @include font_color("font_color20");
  }
  .ant-select-selection__placeholder, 
  .ant-select-search__field__placeholder {
    @include font_color("font_color38");
  }
  .ant-select,
  .ant-select-arrow {
    @include font_color("font_color22");
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
  }

  .ant-select-focused .ant-select-selection, 
  .ant-select-selection:focus, 
  .ant-select-selection:active {
    box-shadow: none;
  }

  // 单选框
  .ant-radio-inner {
    background: none!important;
    border-width: 2px;
    @include border_color("border_color7");
  }
  .ant-radio-checked .ant-radio-inner {
    @include border_color("border_color4");
  }
  .ant-radio-inner::after {
    top: 3px;
    left: 3px;
    width: 6px;
    height: 6px;
  }
  .ant-radio-wrapper {
    @include font_color("font_color3");
    line-height: 32px;
  }
  .ant-radio-wrapper-checked.ant-radio-wrapper {
    @include font_color("font_color1");
  }

  // 多选框
  .ant-checkbox-wrapper {
    @include font_color("font_color3");
  }
  .ant-checkbox-wrapper-checked.ant-checkbox-wrapper {
    @include font_color("font_color1");
  }
  .ant-checkbox-inner {
    background: none!important;
    border-width: 2px;
    @include border_color("border_color7");
  }
  .ant-checkbox-wrapper-checked .ant-checkbox-inner {
    @include background_color("background_color15");
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    @include border_color("border_color1");
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    @include border_color("border_color4");
  }
  .ant-checkbox-inner::after {
    top: 42%;
    left: 19%;
  }
  //修改placeholder的默认颜色：
  input::-webkit-input-placeholder{
    @include font_color("font_color28");
  }
  input::-moz-placeholder{   /* Mozilla Firefox 19+ */
    @include font_color("font_color28");
  }
  input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
    @include font_color("font_color28");
  }
  input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
    @include font_color("font_color28");
  }
  .ant-input:focus {
    @include border_color("border_color1");
    box-shadow: none!important;
  }

  // side
  .ant-menu-submenu-title {
    .svg-icon {
      margin-right: 10px;
      opacity: 0.4;
    }
  }
}
</style>
<style lang="less" scoped>
main {
  width: 1000px;
}
/deep/ .ant-layout-content {
  height: calc(100vh - 0px) !important;
  min-height: 635.5px !important;
  overflow-y: auto;
}
/deep/ .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
/deep/ .ant-menu-vertical .ant-menu-item:not(:last-child),
/deep/ .ant-menu-vertical-left .ant-menu-item:not(:last-child),
/deep/ .ant-menu-vertical-right .ant-menu-item:not(:last-child),
/deep/ .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0 !important;
}
/deep/ .ant-menu-vertical .ant-menu-item,
/deep/ .ant-menu-vertical-left .ant-menu-item,
/deep/ .ant-menu-vertical-right .ant-menu-item,
/deep/ .ant-menu-inline .ant-menu-item,
/deep/ .ant-menu-vertical .ant-menu-submenu-title,
/deep/ .ant-menu-vertical-left .ant-menu-submenu-title,
/deep/ .ant-menu-vertical-right .ant-menu-submenu-title,
/deep/ .ant-menu-inline .ant-menu-submenu-title {
  margin: 0 !important;
}
/deep/ .ant-menu-item.ant-menu-item-selected {
  margin: 0 !important;
}
/deep/ .ant-table-tbody > tr > td {
  padding: 4px !important;
}
// 表格选中颜色
/deep/ .ant-table-row {
  cursor: pointer;
}
/deep/ .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
/deep/ .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  color: #fff;
}

//a-tabs，加tabs-box显示灰色状态
/deep/ .ant-tabs.tabs-box .ant-tabs-bar {
  border-color: #4c4e55;
  padding: 20px 20px 0 20px;
}
/deep/ .ant-tabs.tabs-box .ant-tabs-tab {
  background: transparent !important;
  border-color: #4c4e55 !important;
}
/deep/ .ant-tabs.tabs-box > .ant-tabs-bar .ant-tabs-tab-active {
  background: #4c4e55 !important;
}

// a-month-picker,加picker-box显示透明背景色
/deep/ .picker-box .ant-input {
  color: #fff;
  background-color: transparent;
}
/deep/ .picker-box .ant-calendar-picker-icon {
  color: #fff;
}

// table no-data
/deep/ .ant-table-placeholder {
  background: none;
  color: #fff;
}
/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-pagination-item-ellipsis {
  color: #13c2c2 !important;
}
</style>
<style lang="less">
</style>
