import Nr from '@/utils/NetworldRequest'
const request = (new Nr).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}
export default {
  //规模
  // 获取品牌数据
  getBrandsData({
    type
  }) {
    return request({
      url: `/jjt/dealers/info`,
      method: 'get',
      baseURL: getBaseUrl(type)
    }).then(res => {
      res = res.data.data
      return ({
        account_total: res.account_count,
        monthly_anchor: res.active_author_count,
        occupancy_rate: res.author_rate,
        fans_total: res.fans_count,
        live_num: res.live_count,
        video_releases: res.aweme_count,
        interaction_total: res.polling_count,
        update_time: res.last_update_time,
      })
    })
  },
    
  // 获取账号分级
  getAccountData({
    type
  }) {
    console.log(type)
    return request({
      url: `/jjt/dealers/level`,
      method: 'get',
      baseURL: getBaseUrl(type)
    }).then(res => {
      res = res.data.data || {}
      return ({
          pyramid_num_top: res.level3,
          pyramid_num_middle: res.level2,
          pyramid_num_bottom: res.level1
      })
    })
  },

  // 视频发布
  getVideoReleaseData({
    type,
    begin_time,
    end_time
  }) {
    return request({
      url: `/jjt/dealers/live-aweme-info`,
      method: 'get',
      baseURL: getBaseUrl(type),
      params: {
        begin_time,
        end_time
      },
    }).then(res => {
      res = res.data.data
      return ({
        total: {
          xData: res.day,
          yData: {
            aweme_count_total: res.aweme_count_total,
            live_count_total: res.live_count_total,
            fans_count_total: res.fans_count_total,
          }
        },
        increase: {
          xData: res.day,
          yData: {
            aweme_count_increase: res.aweme_count_increase,
            live_count_increase: res.live_count_increase,
            fans_count_increase: res.fans_count_increase,
          }
        }
      })
    })
  },
  // 视频互动量
  getVideoInteractionData({
    type,
    begin_time,
    end_time
  }) {
    return request({
      url: `/jjt/dealers/live-aweme-info/aweme`,
      method: 'get',
      baseURL: getBaseUrl(type),
      params: {
        begin_time,
        end_time
      },
    }).then(res => {
      res = res.data.data
      return ({
        total: {
          xData: res.day,
          yData: {
            aweme_polling_count_total: res.aweme_polling_count_total,
            aweme_play_count_total: res.aweme_play_count_total,
            aweme_like_count_total: res.aweme_like_count_total,
            aweme_comment_count_total: res.aweme_comment_count_total,
            aweme_share_count_total: res.aweme_share_count_total
          }
        },
        increase: {
          xData: res.day,
          yData: {
            aweme_polling_count_increase: res.aweme_polling_count_increase,
            aweme_play_count_increase: res.aweme_play_count_increase,
            aweme_like_count_increase: res.aweme_like_count_increase,
            aweme_comment_count_increase: res.aweme_comment_count_increase,
            aweme_share_count_increase: res.aweme_share_count_increase
          }
        }
      })
    })
  },
  // 直播互动量
  getLiveInteractionData({
    type,
    begin_time,
    end_time
  }) {
    return request({
      url: `/jjt/dealers/live-aweme-info/live`,
      method: 'get',
      baseURL: getBaseUrl(type),
      params: {
        begin_time,
        end_time
      },
    }).then(res => {
      res = res.data.data
      return ({
        total: {
          xData: res.day,
          yData: {
            live_polling_total: res.live_polling_total,
            live_watch_count_total: res.live_watch_count_total,
            live_like_count_total: res.live_like_count_total,
            live_comment_count_total: res.live_comment_count_total
          }
        },
        increase: {
          xData: res.day,
          yData: {
            live_polling_increase: res.live_polling_increase,
            live_watch_count_increase: res.live_watch_count_increase,
            live_like_count_increase: res.live_like_count_increase,
            live_comment_count_increase: res.live_comment_count_increase
          }
        }
      })
    })
  },


  //表现
  //经销商活跃占比
  getDealerData({
    type
  }) {
    return request({
      url: `/jjt/dealers/management/region-dealer-activation`,
      method: 'get',
      baseURL: getBaseUrl(type)
    }).then(res => {
      return res.data.data
    })
  },

  //内容发布
  getStatisticsData({
    type,
    begin_time,
    end_time
  }) {
    return request({
      url: `/jjt/dealers/management/region-statistics`,
      method: 'get',
      baseURL: getBaseUrl(type),
      params: {
        begin_time,
        end_time
      },
    }).then(res => {
      res = res.data.data
      let list = {
        region: res.region,
        content: {//内容
          '1': res.aweme_count,//短视频
          '2': res.aweme_effective_count,//有效短视频
          '3': res.live_count,//直播
          '4': res.live_effective_count//有效直播
        },
        store: {//店均
          '1': res.live_publish_avg,//店均开播
          '2': res.aweme_publish_avg,//店均视频发布
          '3': res.aweme_publish_avg_frequence//店均发布频次
        },
        short_video: {//短视频
          '1': res.aweme_polling_count,//互动量
          '2': res.aweme_play_count,//播放量
          '3': res.aweme_like_count,//获赞数
          '4': res.aweme_comment_count, //评论数
          '5': res.aweme_share_count//转发数
        },
        live: {//直播
          '1': res.live_polling_count,//互动量
          '2': res.live_watch_count,//观看量
          '3': res.live_like_count,//获赞数
          '4': res.live_comment_count,//评论数
        }
      }
      return list
    })
  },
  // 区域列表
  getAreaList(){
    return request({
      url:'/jjt/dealer/dealers/region-list',
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      method:'get',
    })
  },
  // 大区域透视短视频图表
  getVideoTabTable({
    params,
    type
  }){
    let judgeUrl = ''
    switch (type) {
      case 1:
        judgeUrl =process.env.VUE_APP_OPENDATA_API
        break;
      case 2:
        judgeUrl =process.env.VUE_APP_OPENDATA_KS_API
        break;
      default:
        break;
    }
    return request({
      url: '/jjt/dealers/region/dealers-statistic/aweme',
      method: 'get',
      baseURL:judgeUrl,
      params,
    })
  },
  // 大区透视-按大区、日期对经销商进行数据汇总(直播)
  getLiveTabTable({
    params,type
  }){
    let judgeUrl = ''
    switch (type) {
      case 1:
        judgeUrl =process.env.VUE_APP_OPENDATA_API
        break;
      case 2:
        judgeUrl =process.env.VUE_APP_OPENDATA_KS_API
        break;
      default:
        break;
    }
    return request({
      url:'/jjt/dealers/region/dealers-statistic/live',
      method:'get',
      baseURL:judgeUrl,
      params,
    })
  },
  // 大区透视-top5门店
  getDealerList({
    params,type
  }){
    let judgeUrl = ''
    switch (type) {
      case 1:
        judgeUrl =process.env.VUE_APP_OPENDATA_API
        break;
      case 2:
        judgeUrl =process.env.VUE_APP_OPENDATA_KS_API
        break;
      default:
        break;
    }
    return request({
      url:'/jjt/dealers/region/dealer-list',
      method:'get',
      baseURL:judgeUrl,
      params,
    })
  },
  //
  getPrincipalInfo(params) {
    const { type, ...otherParams } = params

    return request({
      url: '/jjt/benz/principal/info',
      method: 'get',
      baseURL: getBaseUrl(type),
      params: otherParams
    })
  },
  getLiveAweme(params) {
    const { type, ...otherParams } = params

    return request({
      url: '/jjt/benz/live-aweme',
      method: 'get',
      baseURL: getBaseUrl(type),
      params: otherParams
    })
  }
}
