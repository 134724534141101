import api from '@/api/dealerOfOneself'

const {
    //规模
    getBrandsData,
    getAccountData,
    getVideoReleaseData,
    getVideoInteractionData,
    getLiveInteractionData,

    //表现
    getDealerData,
    getStatisticsData
} = api;

export default {
    namespaced: true,

    state: {
        brandsData: {},
        accountData: {},

        //loading
        videoReleaseLoading: false,
        videoInteractionLoading: false,
        liveInteractionLoading: false,
        //累计总量
        videoReleaseTotal: {},
        videoInteractionTotal: {},
        liveInteractionTotal: {},
        //每日增量
        videoReleaseIncrease: {},
        videoInteractionIncrease: {},
        liveInteractionIncrease: {},

        dealerData: [],
        videoTabLoading: true,
        videoData: [],
    },

    actions: {
        // 品牌数据
        getBrandsData({ commit }, payload) {
            let {
                type
            } = payload

            return getBrandsData({
                type
            })
                .then((res) => {
                    commit('changeState', { name: 'brandsData', data: res })
                })
        },
        // 账号分级
        getAccountData({ commit }, payload) {
            let {
                type
            } = payload

            return getAccountData({
                type
            })
                .then((res) => {
                    commit('changeState', { name: 'accountData', data: res })
                })
        },
        // 视频发布
        getVideoReleaseData({ commit }, payload) {
            let {
                type,
                begin_time,
                end_time
            } = payload

            commit('changeState', { name: 'videoReleaseLoading', data: true })

            return getVideoReleaseData({
                type,
                begin_time,
                end_time
            })
                .then((res) => {
                    commit('changeState', { name: 'videoReleaseLoading', data: false })
                    commit('changeState', { name: 'videoReleaseTotal', data: res.total })
                    commit('changeState', { name: 'videoReleaseIncrease', data: res.increase })
                })
        },
        getVideoInteractionData({ commit }, payload) {
            let {
                type,
                begin_time,
                end_time
            } = payload

            commit('changeState', { name: 'videoInteractionLoading', data: true })

            return getVideoInteractionData({
                type,
                begin_time,
                end_time
            })
                .then((res) => {
                    commit('changeState', { name: 'videoInteractionLoading', data: false })
                    commit('changeState', { name: 'videoInteractionTotal', data: res.total })
                    commit('changeState', { name: 'videoInteractionIncrease', data: res.increase })
                })
        },
        getLiveInteractionData({ commit }, payload) {
            let {
                type,
                begin_time,
                end_time
            } = payload

            commit('changeState', { name: 'liveInteractionLoading', data: true })

            return getLiveInteractionData({
                type,
                begin_time,
                end_time
            })
                .then((res) => {
                    commit('changeState', { name: 'liveInteractionLoading', data: false })
                    commit('changeState', { name: 'liveInteractionTotal', data: res.total })
                    commit('changeState', { name: 'liveInteractionIncrease', data: res.increase })
                })
        },


        getDealerData({ commit }, payload) {
            let {
                type
            } = payload

            return getDealerData({
                type
            })
                .then((res) => {
                    commit('changeState', { name: 'dealerData', data: res })
                })
        },
        getStatisticsData({ commit }, payload) {
            let {
                type,
                begin_time,
                end_time
            } = payload

            commit('changeState', { name: 'videoTabLoading', data: true })

            return getStatisticsData({
                type,
                begin_time,
                end_time
            })
                .then((res) => {
                    commit('changeState', { name: 'videoTabLoading', data: false })
                    commit('changeState', { name: 'videoData', data: res })
                })
        },
    },

    mutations: {
        changeState(state, payload) {
            let {
                data,
                name
            } = payload;
            state[name] = data;
        }
    }
}
