import api from '@/api/brand_rectangle'

const {
  getAreaList,
  getMqttConfig,
  addLiveRoom,
  getOnlineCount,
  getFansCount,
  getAllCount,
  getMapData
} = api;

export default {
  namespaced: true,

  state: {
    areaList: {},
    mqttConfigData: null,
    onlineCountData: null,
    fansCountData: null,
    allCountData: null,
    mapData: {},
    addRoomRes: null
  },

  actions: {
    // 获取区域
    getAreaList({ commit }) {
      return getAreaList()
        .then((res) => {
          if (res.data.code == 0) {
            let arr = [];
            if (res.data.data.type == 10) {
              arr.push({
                value: '',
                label: '全部'
              })
            }
            res.data.data.region_list.forEach(element => {
              let value = {
                value: element.id,
                label: element.name
              }
              arr.push(value)
            });
            commit('changeState', {
              name: 'areaList', data: {
                region_list: arr,
                type: res.data.data.type
              }
            })
          }

        })
    },
    async loadConfig({
      commit,
      dispatch
    }, payload) {

      let {
        anchorId,
        deviceId,
        platformType
      } = payload;

      let reqQueue = [{
        dispatch_name: 'getMqttConfig',
        state_name: 'mqttConfigData'
      }
      ];
      let resp = await Promise.all(reqQueue.map(val => dispatch(val.dispatch_name, {
        anchorId,
        deviceId,
        platformType
      })));

      console.log('resp', resp);
      // commit 状态
      resp.forEach((res, idx) => {
        if (res.status === 200 && !res.data.errcode) {
          commit('changeState', {
            data: res.data,
            name: reqQueue[idx].state_name
          })
          return;
        }
        throw new Error(res.data.errmsg);
      })

    },
    async loadOnlineCount({
      commit,
      dispatch
    }, payload) {

      let {
        roomId,
        deviceId
      } = payload;

      let reqQueue = [{
        dispatch_name: 'getOnlineCount',
        state_name: 'onlineCountData'
      }
      ];
      let resp = await Promise.all(reqQueue.map(val => dispatch(val.dispatch_name, {
        roomId,
        deviceId
      })));

      console.log('resp', resp);
      // commit 状态
      resp.forEach((res, idx) => {
        if (res.status === 200 && !res.data.errcode) {
          commit('changeState', {
            data: res.data,
            name: reqQueue[idx].state_name
          })
          return;
        }
        throw new Error(res.data.errmsg);
      })

    },
    async loadFansCount({
      commit,
      dispatch
    }, payload) {

      let {
        authorId
      } = payload;

      let reqQueue = [{
        dispatch_name: 'getFansCount',
        state_name: 'fansCountData'
      }
      ];
      let resp = await Promise.all(reqQueue.map(val => dispatch(val.dispatch_name, {
        authorId
      })));

      console.log('resp', resp);
      // commit 状态
      resp.forEach((res, idx) => {
        if (res.status === 200 && !res.data.errcode) {
          commit('changeState', {
            data: res.data,
            name: reqQueue[idx].state_name
          })
          return;
        }
        throw new Error(res.data.errmsg);
      })

    },
    async loadAllCount({
      commit,
      dispatch
    }, payload) {

      let {
        platformType,
        roomId
      } = payload;

      let reqQueue = [{
        dispatch_name: 'getAllCount',
        state_name: 'allCountData'
      }
      ];
      let resp = await Promise.all(reqQueue.map(val => dispatch(val.dispatch_name, {
        platformType,
        roomId
      })));

      console.log('resp', resp);
      // commit 状态
      resp.forEach((res, idx) => {
        if (res.status === 200 && !res.data.errcode) {
          commit('changeState', {
            data: res.data,
            name: reqQueue[idx].state_name
          })
          return;
        }
        throw new Error(res.data.errmsg);
      })

    },
    async loadMapData({
      commit,
      dispatch
    }, payload) {
      let {
        principal_id,
        region_id,
        platform
      } = payload;

      let reqQueue = [{
        dispatch_name: 'getMapData',
        state_name: 'mapData'
      }
      ];
      let resp = await Promise.all(reqQueue.map(val => dispatch(val.dispatch_name, {
        principal_id,
        region_id,
        platform
      })));

      console.log('resp', resp);
      // commit 状态
      resp.forEach((res, idx) => {
        // if (res.status === 200 && !res.data.errcode) {
        //   let newData = res.data

        //   let geoCoordMap = {}
        //   let data = []
        //   newData.dealers.forEach((item) => {
        //     geoCoordMap[item.author.nickname] = [item.lng, item.lat]
        //     data.push({
        //       'name': item.author.nickname,
        //       'value': item.total_user,
        //       'cover': item.cover
        //     })
        //   })
        //   newData.geoCoordMap = geoCoordMap
        //   newData.data = data
        //   commit('changeState', {
        //     data: newData,
        //     name: reqQueue[idx].state_name
        //   })
        //   return;
        // }
        // throw new Error(res.data.errmsg);
      })

    },
    // 获取mqtt配置
    getMqttConfig({ commit }, payload) {
      let {
        platform_type,
        author_id,
        device_id
      } = payload;
      return getMqttConfig({
        platform_type,
        author_id,
        device_id
      }).then((res) => {
        console.log('getMqttConfig res', res)
        commit('changeState', { name: 'mqttConfigData', data: res.data })
      })
    },
    // 添加直播监听
    addLiveRoom({ commit }, payload) {
      let {
        platform_type,
        room_id
      } = payload;
      return addLiveRoom({
        platform_type,
        room_id
      }).then((res) => {
        console.log('addLiveRoom res', res)
        commit('changeState', { name: 'addRoomRes', data: res })
      })
    },
    // 获取在线数
    getOnlineCount(ctx, payload) {
      let {
        roomId
      } = payload;
      return getOnlineCount({
        roomId
      });
    },
    // 获取粉丝数
    getFansCount(ctx, payload) {
      let {
        authorId
      } = payload;
      return getFansCount({
        authorId
      });
    },
    // 获取主播粉丝数 和 累计观看人数 new
    getAllCount(ctx, payload) {
      let {
        platformType,
        roomId
      } = payload;
      return getAllCount({
        platformType,
        roomId
      });
    },
    // 获取地图数据
    getMapData({ commit }, payload) {
      let {
        principal_id,
        region_id,
        platform
      } = payload;
      return getMapData({
        principal_id,
        region_id,
        platform
      }).then((res) => {
        console.log('getMapData res', res)
        commit('changeState', { name: 'mapData', data: res })
      })
    },
  },

  mutations: {
    changeState(state, payload) {
      let {
        data,
        name
      } = payload;
      state[name] = data;
    }
  }
}
