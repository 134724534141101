import Cookies from 'js-cookie'

const TokenKey = 'jjt_token'
const UserIdKey = 'user_id'
const PrincipalIdKey = 'principal_id'
const PrincipalLogoKey = 'principal_logo'
const PrincipalListKey = 'principal_list'
const PrincipalNameKey = 'principal_name'
const RoutesKey = 'jjt_routes'
const ThemeKey = 'theme'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserId() {
  return Cookies.get(UserIdKey)
}

export function setUserId(id) {
  return Cookies.set(UserIdKey, id)
}

export function removeUserId() {
  return Cookies.remove(UserIdKey)
}

export function getPrincipalId() {
  return Cookies.get(PrincipalIdKey)
}

export function setPrincipalId(id) {
  return Cookies.set(PrincipalIdKey, id)
}

export function removePrincipalId() {
  return Cookies.remove(PrincipalIdKey)
}

export function getPrincipalLogo() {
  return Cookies.get(PrincipalLogoKey)
}

export function setPrincipalLogo(url) {
  return Cookies.set(PrincipalLogoKey, process.env.VUE_APP_LIVE_OSS_HOST + url)
}

export function removePrincipalLogo() {
  return Cookies.remove(PrincipalLogoKey)
}

export function getPrincipalList() {
  return Cookies.get(PrincipalListKey)
}

export function setPrincipalList(list) {
  return Cookies.set(PrincipalListKey, list)
}

export function removePrincipalList() {
  return Cookies.remove(PrincipalListKey)
}

export function getPrincipalName() {
  return Cookies.get(PrincipalNameKey)
}

export function setPrincipalName(name) {
  return Cookies.set(PrincipalNameKey, name)
}

export function removePrincipalName() {
  return Cookies.remove(PrincipalNameKey)
}

export function getRoutes() {
  return Cookies.get(RoutesKey)
}

export function setRoutes(routes) {
  return Cookies.set(RoutesKey, routes)
}

export function removeRoutes() {
  return Cookies.remove(RoutesKey)
}

export function getTheme() {
  return Cookies.get(ThemeKey)
}

export function setTheme(theme) {
  return Cookies.set(ThemeKey, theme)
}

export function removeTheme() {
  return Cookies.remove(ThemeKey)
}
