import Nr from '@/utils/NetworldRequest'
const request = new Nr().getAxios()
const loginRequest = new Nr({needTocken: false}).getAxios()
const casRequest = new Nr({ serviceName: 'cas' }).getAxios()
export default {
  // 登录
  login(data) {
    return loginRequest({
      url: `/oauth/wxapt/token`,
      baseURL: process.env.VUE_APP_OPENDATA_AGG_API,
      method: 'post',
      data
    })
  },
  // 退出登录
  logOut() {
    return casRequest({
      url: `/ums/user/logout`,
      method: 'get'
    })
  },
  // 获取路由
  getAuthority() {
    return casRequest({
      url: `/ums/user/authority`,
      method: 'get',
      params: {
        client_module_id: 15
      }
    })
  },
  // 获取全站地图路由
  getAreaAuthority() {
    return casRequest({
      url: `/ums/user/authority`,
      method: 'get',
      params: {
        client_module_id: 13
      }
    })
  },
  // 获取主体
  getPrincipals() {
    return request({
      url: `/oauth/principals`,
      method: 'get'
    })
  },
  // 获取二维码
  getSweepCode(){
    return loginRequest({
      url:'/wx/qr/code/ticket',
      method:'get',
      baseURL: process.env.VUE_APP_WEIAPI,
      params:{
        appid:process.env.VUE_APP_WX_APPID
      }
    })
  }
}
