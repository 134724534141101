<template>
  <a-dropdown class="aft-dropdown">
    <div class="header-avatar" style="cursor: pointer">
      <a-avatar class="avatar" size="small" shape="circle" :src="principalLogo"/>
      <span class="name">{{principalName}}</span>
    </div>
    <a-menu :class="['avatar-menu', 'aft-menu']" slot="overlay">
      <a-menu-item v-for="(item, index) in principalList" :key="index" @click="principalsChange(item)">
        <span>{{item.principal_name}}</span>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item @click="logout">
        <a-icon style="margin-right: 8px;" type="poweroff" />
        <span>退出登录</span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } = createNamespacedHelpers("auth");
import { 
  setPrincipalId, 
  setPrincipalName,
  setPrincipalLogo,
  removeToken, 
  removeUserId, 
  removePrincipalId, 
  removeRoutes, 
  getPrincipalLogo, 
  getPrincipalList, 
  getPrincipalName, 
  removePrincipalLogo, 
  removePrincipalList, 
  removePrincipalName 
} from '@/utils/auth'

export default {
  name: 'HeaderAvatar',
  computed: {
    data() {
      return {
        principalLogo: '',
        principalName: '',
        principalList: []
      }
    },
    ...mapState({
      name: state => state.name,
      avatar: state => state.avatar,
      list: state => state.list,
    }),
  },
  created() {
    this.principalLogo = getPrincipalLogo()
    this.principalList = JSON.parse(getPrincipalList())
    this.principalName = getPrincipalName()
    console.log(1234, this.principalList)
  },
  methods: {
    ...mapMutations(["SET_NAME", "SET_AVATAR"]),
    ...mapActions(['logOut']),
    // 退出登录
    logout() {
      this.logOut()
        .then(() => {
          removeToken()
          removeUserId()
          removePrincipalId()
          removePrincipalLogo()
          removePrincipalList()
          removePrincipalName()
          removeRoutes()
          this.$router.push('/login')
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 主体切换
    principalsChange(item) {
      setPrincipalId(item.principal_id)
      setPrincipalLogo(item.logo)
      setPrincipalName(item.principal_name)
      this.SET_NAME(item.principal_name)
      this.SET_AVATAR(`${process.env.VUE_APP_LIVE_OSS_HOST}${item.logo}`)
      // 刷新
      this.$router.go(0)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/_handle.scss';
  .header-avatar{
    display: inline-flex;
    .avatar, .name{
      align-self: center;
      @include font_color("font_color1");
    }
    .avatar{
      margin-right: 8px;
    }
    .name{
      font-weight: 500;
    }
  }
  .avatar-menu{
    width: 150px;
  }

</style>
