/* *
* 分页组件
* @module /src/components/BasePaginationr.vue
* @desc 列表分页组件
* @author wenghaocan@3ncto.com
* @date 2020年06月17日
* @param {String} [currentPage] - 当前页数
* @param {String} [pageSize] - 每页条数
* @param {String} [total] - 数据总数
* @example 调用示例
*  <base-pagination :currentPage="currentPage" :pageSize="pageSize" :total="total" @onChange="onChange" @onShowSizeChange="onChange"></base-pagination>
* */
// :show-total="total => `共 ${total} 条`"
// show-size-changer
// show-quick-jumper

<template>
    <div class="pagination-options" v-if="total > 0">
        <a-pagination
            v-model="current"
            :default-current="current"
            :default-pageSize="size"
            :total="total"
            :page-size.sync="size"
            :page-size-options="options"
            @change="bindCurrentChange"
            @showSizeChange="bindSizeChange"
            size="small"
        />
    </div>
</template>
<script>
export default {
    name: "BasePagination",
    data() {
        return {
            current: 0,
            size: 0
        };
    },
    props: {
        currentPage: {
            require: true,
            type: Number
        },
        pageSize: {
            require: true,
            type: Number
        },
        total: {
            require: true,
            type: Number
        },
        options: {
            type: Array,
            default: function () {
                return  ['5', '10', '15']
            }
        }
    },
	watch: {
		currentPage: {
			handler: function(val) {
				this.current = val
			},
			immediate: true,
			deep: true
        },
        pageSize: {
			handler: function(val) {
				this.size = val
			},
			immediate: true,
			deep: true
		},
	},
    methods: {
        // 分页事件-修改当前页码
        bindCurrentChange(current, pageSize) {
            this.$emit('onChange', current, pageSize)
        },
        // 分页事件-修改当前页数
        bindSizeChange(current, pageSize) {
            this.$emit('onShowSizeChange', 1, pageSize)
        }
    },
    mounted() {
        
    }
};
</script>
<style lang="less">
.pagination-options {
    text-align: center;
    margin-top: 16px;
    .ant-pagination,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, 
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
        color: #fff;
    }
}
</style>