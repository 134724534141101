import auth from './auth'
import setting from './setting'
import dealerOfOneself from './dealerOfOneself'
import topicMarketing from './topicMarketing'
import brand_rectangle from './brand_rectangle'
import brandSummary from './brandSummary'


export default {
    auth,
    setting,
    dealerOfOneself,
    topicMarketing,
    brand_rectangle,
    brandSummary,
}